<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="title mb-3">{{ selectedServiceInfo.serviceName }}</div>
        <div class="department-name">
          <strong>Клиника:</strong> {{ selectedServiceInfo.departmentName }}
        </div>
        <div class="service-name" v-if="selectedServiceInfo.doctorName">
          <strong>Доктор:</strong> {{ selectedServiceInfo.doctorName }}
        </div>
        <div class="price">
          <strong>Цена: </strong>{{ selectedServiceInfo.servicePrice }} руб.
        </div>
        <div class="city-district">
          <strong>Район города: </strong>{{ selectedServiceInfo.cityPart }}
        </div>
      </v-col>
      <v-col>
        <order-form :services="this.orderData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
import OrderForm from "components/partials/OrderForm";

export default {
  name: "MainResultServiceFast",
  components: { OrderForm },
  data: () => ({
    orderData: [],
  }),
  mounted() {
    this.collectOrderData();
  },
  computed: {
    ...mapGetters({
      objSearchServiceDetail: names.OBJ_SEARCH_SERVICE_DETAIL,
      selectedServiceInfo: names.SELECTED_SERVICE_INFO,
      entityId: names.ENTITY_ID,
    }),
  },
  methods: {
    ...mapActions({}),
    returnDetail() {
      this.$drawer.showDrawer(true, "Detail");
    },

    collectOrderData() {
      this.orderData = {
        departmentName: this.selectedServiceInfo.departmentName,

        departmentId: this.selectedServiceInfo.departmentId,

        departmentAddress: this.selectedServiceInfo.departmentAddress,

        departmentPhone: "-",

        serviceName: this.selectedServiceInfo.serviceName,

        doctorName: this.selectedServiceInfo.doctorName,

        doctorServicePrice: null,

        servicePrice: this.selectedServiceInfo.servicePrice,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.return-btn {
  color: $deep-blue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.v-text-field--outlined {
  .v-input__control {
    .v-input__slot {
      min-height: unset !important;
      height: 38px !important;
    }
  }
}
</style>
